// @ts-expect-error TS(7016): Could not find a declaration file for module 'js-c... Remove this comment to see the full error message
import Cookies from 'js-cookie';

import {
	isBitSet,
	numberOfSetBits as numberOfSetBitsfromIntegerUtils,
} from 'Utils/integerUtils';
import { getQueryObject } from 'Utils/urlUtils';

import { COOKIE, HSID_DEFAULT_VALUE } from 'Constants/constants';

import { hashCode } from '../gen';

class Experiment {
	experimentName: string;
	bucketName: Array<string>;
	bucketWeight: Array<number>;

	checkAssertion(condition: boolean, error: string) {
		if (!condition) {
			throw new Error(error);
		}
	}

	constructor(
		experimentName: string,
		bucketName: Array<string>,
		bucketWeight: Array<number>,
	) {
		this.checkAssertion(
			bucketName.length === bucketWeight.length,
			'buckets size should match',
		);
		this.experimentName = experimentName;
		this.bucketName = bucketName;
		this.bucketWeight = bucketWeight;
	}

	getCookieHashValueForExperiment(cookieHash: any, experimentName: any) {
		let cookieHashForExperiment = 0;
		const experimentHash = hashCode(experimentName);

		for (let i = 0, j = 0; i < 32; ++i) {
			if (isBitSet(experimentHash, i)) {
				if (isBitSet(cookieHash, i)) {
					cookieHashForExperiment |= 1 << j;
				}
				j += 1;
			}
		}
		cookieHashForExperiment += 1;

		return cookieHashForExperiment;
	}

	range(bitCount: any) {
		return 1 << bitCount;
	}

	bucketCount() {
		return (this as any).bucketName.length;
	}

	resolveBucketIndex(cookieValueForExperiment: any, cookieValueRange: any) {
		let index = -1;
		const len = (this as any).bucketName.length;
		let sumBucketweight = 0;
		let cumBucketweight = 0;
		for (let i = 0; i < len; ++i) {
			sumBucketweight += (this as any).bucketWeight[i];
		}

		for (let i = 0; i < len; ++i) {
			cumBucketweight += (this as any).bucketWeight[i];
			if (
				cumBucketweight * cookieValueRange >=
				cookieValueForExperiment * sumBucketweight
			) {
				index = i;
				break;
			}
		}

		return index;
	}

	getBucket(uniqueId: any) {
		const experimentOverride =
			typeof window !== 'undefined'
				? getQueryObject(window?.location)?.[
						COOKIE.EXPERIMENT_OVERRIDE
				  ] ?? Cookies.get('experimentOverride')
				: null;
		if (
			experimentOverride &&
			typeof experimentOverride === 'string' &&
			(this as any).bucketName?.includes?.(experimentOverride)
		)
			return experimentOverride;
		if (
			experimentOverride &&
			typeof experimentOverride === 'string' &&
			(this as any).bucketName?.includes?.(
				experimentOverride.replaceAll('_', ' '),
			)
		)
			return experimentOverride.replaceAll('_', ' ');
		let bucket = (this as any).bucketName[0];
		if (uniqueId !== null && uniqueId !== HSID_DEFAULT_VALUE) {
			const cookieString = uniqueId.getBase64EncodedValue();
			const cookieHash = hashCode(cookieString);
			const numberOfSetBits = numberOfSetBitsfromIntegerUtils(
				hashCode((this as any).experimentName),
			);
			const cookieValueRange = this.range(numberOfSetBits);

			const cookieValueForExperiment =
				this.getCookieHashValueForExperiment(
					cookieHash,
					(this as any).experimentName,
				);

			const bucketIndex = this.resolveBucketIndex(
				cookieValueForExperiment,
				cookieValueRange,
			);

			if (bucketIndex < 0 || bucketIndex >= this.bucketCount()) {
				const fallbackBucketIndex =
					this.bucketCount() - 1 > 0 ? this.bucketCount() - 1 : 0;
				bucket = (this as any).bucketName[fallbackBucketIndex];

				// Raven.captureMessage(`Experiment ${this.experimentName} : This unique id does not belong to any bucket. Bug. UniqueId : ${uniqueId}`);
			} else {
				bucket = (this as any).bucketName[bucketIndex];
			}
		}
		return bucket;
	}
}

export default Experiment;
