import * as React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

// eslint-disable-next-line import/no-unresolved
import tokens from '@headout/aer/src/tokens/index';

import {
	FbLogoFilledSvg,
	InstagramLogoFilledSvg,
	LinkedInLogoFilledSvg,
	XLogoFilledSvg,
	YoutubeLogoFilledSvg,
} from 'Assets/svg/social';

import { SOCIAL_LINKS } from 'Constants/constants';

const StyledSocialLinks = styled.ul<{
	isDarkBg: boolean;
}>`
	display: flex;
	justify-content: flex-start;
	gap: 0.1875rem;
	align-items: center;
`;

const SocialIcon = styled.li<{
	isDarkBg: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;

	& svg {
		fill: ${({ isDarkBg }) =>
			isDarkBg ? tokens.colors.GRAY.G8 : tokens.colors.GRAY.G3};
	}

	&:hover {
		& svg {
			fill: ${({ isDarkBg }) =>
				isDarkBg
					? tokens.colors.BRAND_COLORS.WHITE
					: tokens.colors.BRAND_COLORS.PURPS};
		}
	}
`;

const SocialMedia = ({
	isDarkBg = false,
	currentCityCode,
}: {
	isDarkBg?: boolean;
	currentCityCode?: string;
}) => {
	const SOCIAL_DETAILS = [
		{
			href: SOCIAL_LINKS.LINKEDIN_URL,
			icon: <LinkedInLogoFilledSvg key={'linkedin'} />,
		},
		{
			href:
				currentCityCode === 'DUBAI'
					? SOCIAL_LINKS.INSTAGRAM_HEADOUT_DUBAI_URL
					: SOCIAL_LINKS.INSTAGRAM_HEADOUT_URL,
			icon: <InstagramLogoFilledSvg key={'instagram'} />,
		},
		{
			href: SOCIAL_LINKS.YOUTUBE_URL,
			icon: <YoutubeLogoFilledSvg key={'youtube'} />,
		},
		{
			href: SOCIAL_LINKS.FB_URL,
			icon: <FbLogoFilledSvg key={'facebook'} />,
		},
		{
			href: SOCIAL_LINKS.TWITTER_URL,
			icon: <XLogoFilledSvg key={'twitter'} />,
		},
	];

	return (
		<StyledSocialLinks isDarkBg={isDarkBg}>
			{SOCIAL_DETAILS.map(item => {
				return (
					<SocialIcon key={item.icon.key} isDarkBg={isDarkBg}>
						<a
							href={item.href}
							target='_blank'
							rel='noreferrer noopener'
						>
							{item.icon}
						</a>
					</SocialIcon>
				);
			})}
		</StyledSocialLinks>
	);
};

export default SocialMedia;
