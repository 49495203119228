import { ActionTypes } from 'Actions/actions';
import { CollectionListStoreType } from 'ReduxTypes/collections';

const initialState = {
	byCityCode: {},
};
export const collectionsList = (
	state: CollectionListStoreType = initialState,
	action: any,
) => {
	switch (action.type) {
		case ActionTypes.REQUEST_COLLECTIONS_LIST: {
			const { params } = action.payload;
			const map = { isFetching: true };
			return {
				...state,
				[params]: {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					...state?.[params],
					...map,
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_LIST: {
			const {
				params,
				metaData,
				pageData: lastUpdatedPageInfo,
				response,
				receivedAt,
			} = action.payload;
			const { nextOffset, prevOffset, total, prevUrl, nextUrl } =
				response;

			const collectionIdList = response.collections?.map(
				({ id }: { id: string }) => id,
			);
			const partialMap = {
				metaData,
				lastUpdatedPageInfo,
				collectionIdList,
				receivedAt,
				nextOffset,
				prevOffset,
				total,
				prevUrl,
				nextUrl,
				isFetching: false,
			};

			return {
				...state,
				[params]: partialMap,
			};
		}
		case ActionTypes.REQUEST_COLLECTIONS_LIST_BY_CITY: {
			const { params, cityCode } = action.payload;
			const map = { isFetching: true };
			return {
				...state,
				byCityCode: {
					...state.byCityCode,
					[cityCode]: {
						...state.byCityCode?.[cityCode],
						[params]: {
							...state.byCityCode?.[cityCode]?.[params],
							...map,
						},
					},
				},
			};
		}
		case ActionTypes.RECEIVE_COLLECTIONS_LIST_BY_CITY: {
			const {
				params,
				metaData,
				pageData: lastUpdatedPageInfo,
				response,
				receivedAt,
				cityCode,
				...rest
			} = action.payload;
			const { result } = response;
			const { collectionCards } = result;
			const { items, nextOffset, prevOffset, total, prevUrl, nextUrl } =
				collectionCards;

			const collectionIdList = items?.map(({ id }: { id: string }) => id);
			const partialMap = {
				metaData,
				lastUpdatedPageInfo,
				collectionIdList,
				receivedAt,
				nextOffset,
				prevOffset,
				total,
				prevUrl,
				nextUrl,
				isFetching: false,
				...rest,
			};

			return {
				...state,
				byCityCode: {
					...state.byCityCode,
					[cityCode]: {
						...state.byCityCode?.[cityCode],
						[params]: {
							...state.byCityCode?.[cityCode]?.[params],
							...partialMap,
						},
					},
				},
			};
		}

		default:
			return state;
	}
};
