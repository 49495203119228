import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import Image from 'Components/common/image';

import { PAYMENT_CARD_ICONS } from 'Constants/constants';

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 35px);
`;

const GridItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
`;

const PAYMENT_METHOD_DIM = 28;

const AcceptedMethods: React.FC<React.PropsWithChildren<unknown>> = () => {
	const PAYMENT_METHODS = Object.entries(PAYMENT_CARD_ICONS);

	return (
		<Grid>
			{PAYMENT_METHODS.map(([iconName, iconURL]) => {
				return (
					<GridItem key={iconName}>
						<Image
							src={iconURL}
							alt={iconName}
							width={PAYMENT_METHOD_DIM}
							height={PAYMENT_METHOD_DIM}
							layout={'responsive'}
							placeholder={'empty'} // image is small, so no need for blur
						/>
					</GridItem>
				);
			})}
		</Grid>
	);
};

export default AcceptedMethods;
