// eslint-disable-next-line no-restricted-imports
import { createGlobalStyle } from 'styled-components';

import colors from 'Static/typography/colors';

export const GlobalFocusStyle = createGlobalStyle`
    a:focus-visible {
        box-shadow: 0 0 0 2px ${colors.BRAND_COLORS.PURPS};
        border-radius: .2rem;
    }

    a:has(span.city-name):focus-visible {
        box-shadow: none;

        & > div {
            outline: .2rem solid ${colors.BRAND_COLORS.PURPS};
            outline-offset: .2rem;
            border-radius: .2rem;
        }
    }

    a.collection-card:focus-visible,
    a.city-card-wrapper:focus-visible {
        box-shadow: none;

        .collection-name,
        .city-title {
            box-shadow: 0 0 0 .2rem ${colors.BRAND_COLORS.PURPS};
            border-radius: .4rem;
        }
    }

    button:focus-visible {
        outline: auto ${colors.BRAND_COLORS.PURPS} !important;
        transition: none;
    }

    div:focus-visible {
        outline: 0.2rem solid ${colors.BRAND_COLORS.PURPS};
        transition: none;
    }
`;
