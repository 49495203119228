import { ActionTypes } from 'Actions/actions';

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const fetchingStatus = (state = { isFetching: 0 }, action) => {
	switch (action.type) {
		// FIXME: WTF is going on over here. Why do request and response have different types?
		// This is such a bad way of maintaining a state for ongoing and finished actions.
		case ActionTypes.REQUEST_PRODUCT:
		case ActionTypes.REQUEST_REVIEWS_BY_TGID:
		case ActionTypes.REQUEST_PRODUCT_LIST:
		case ActionTypes.REQUEST_CITIES:
		case ActionTypes.REQUEST_USER_GEO_LOCATION:
		case ActionTypes.REQUEST_CURRENCIES:
		case ActionTypes.REQUEST_PRODUCTS:
		case ActionTypes.REQUEST_NEARBY_CITIES_FROM_LAT_LONG:
		case ActionTypes.INCREMENT_API_COUNT:
		case ActionTypes.REQUEST_LANGUAGES: {
			return {
				...state,
				isFetching: state?.isFetching + 1,
			};
		}
		case ActionTypes.RECEIVE_PRODUCT:
		case ActionTypes.RECEIVE_REVIEWS_BY_TGID:
		case ActionTypes.RECEIVE_PRODUCT_LIST:
		case ActionTypes.RECEIVE_CITIES:
		case ActionTypes.RECEIVE_NEARBY_CITIES_FROM_LAT_LONG:
		case ActionTypes.RECEIVE_USER_GEO_LOCATION:
		case ActionTypes.RECEIVE_CURRENCIES:
		case ActionTypes.RECEIVE_PRODUCTS:
		case ActionTypes.RECEIVE_LANGUAGES:
		case ActionTypes.DECREMENT_API_COUNT: {
			return {
				...state,
				isFetching: Math.max(state?.isFetching - 1, 0),
			};
		}
		default:
			return state;
	}
};
