import React, { ReactNode } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import Conditional from 'Components/common/conditional';

import { CloseIcon } from 'Assets/svg/checkout/CloseIcon';

import { MODAL_CLOSE_REASON } from 'Constants/constants';

const CoreModalContainer = styled.div`
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	opacity: 1;
	position: fixed;
	top: 0;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	width: 100%;
	z-index: 999;

	.right-end-modal {
		align-items: flex-start !important;
		justify-content: flex-end !important;
	}

	.core-modal-content {
		align-items: center;
		background: #fff;
		-webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 50%;
		min-width: 80%;
		overflow: auto;
		position: relative;
	}

	.core-modal-content.display-block {
		display: block;
	}
	.core-modal-content.fixed-width {
		min-width: auto;
		width: 23.4375rem;
	}
	.core-modal-content.large-fixed-width {
		min-width: auto;
		width: 37.5rem;
	}
	.core-modal-content.full-width {
		min-width: auto;
		width: 100vw;
	}
	.core-modal-content.full-width-height {
		height: 100vh;
		min-width: auto;
		width: 100vw;
	}
	.core-modal-hide {
		height: 0;
		opacity: 0;
		top: 50%;
	}
`;

export const CloseButton = styled.div`
	height: 1.5rem;
	width: 1.5rem;
	z-index: 2;
	position: absolute;
	top: 1.5rem;
	right: 0.75rem;
	margin: 0.5rem;
	cursor: pointer;
`;

type OwnProps = {
	containerClassName?: string;
	containerHideClassName?: string;
	contentClassName?: string;
	open?: boolean;
	showCloseButton?: boolean;
	onRequestClose?: (...args: any[]) => any;
	children?: ReactNode;
};

/**
 * This is a core modal component
 * The show/hide animation styles can be overridden via a combination of
 * "containerClassName" and "containerHideClassName"
 */
class Modal extends React.Component<OwnProps> {
	static defaultProps = {
		containerClassName: '',
		containerHideClassName: '',
		contentClassName: '',
	};

	componentDidMount() {
		this.toggleBodyOverflow(this.props.open);
		window.addEventListener('keydown', this.handleKeyDown);
	}

	UNSAFE_componentWillReceiveProps(nextProps: any) {
		this.toggleBodyOverflow(nextProps.open);
	}

	componentWillUnmount() {
		this.toggleBodyOverflow(false);
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	bodyHasOverflowHidden() {
		return window.document.body.className.includes('overflow-hidden');
	}

	handleKeyDown = (event: KeyboardEvent) => {
		const { open, onRequestClose } = this.props;
		if (event.key === 'Escape' && open && onRequestClose) {
			onRequestClose();
		}
	};

	// Prevents scrolling background
	toggleBodyOverflow = (isOpen: any) => {
		if (isOpen === true && !this.bodyHasOverflowHidden()) {
			window.document.body.className +=
				window.document.body.className === ''
					? 'overflow-hidden'
					: ' overflow-hidden';
		} else if (!isOpen) {
			window.document.body.className =
				window.document.body.className.replace(
					/(?:\soverflow-hidden|overflow-hidden\s|^overflow-hidden)/,
					'',
				);
		}
	};

	render() {
		const {
			open,
			containerClassName,
			containerHideClassName,
			contentClassName,
			onRequestClose,
			children,
			// @ts-expect-error TS(2339): Property 'preserveContent' does not exist on type ... Remove this comment to see the full error message
			preserveContent,
			// @ts-expect-error TS(2339): Property 'dimensionsStyle' does not exist on type ... Remove this comment to see the full error message
			dimensionsStyle,
			showCloseButton,
		} = this.props;
		return preserveContent || open ? (
			<CoreModalContainer
				className={`core-modal-container ${containerClassName} ${
					!open ? `core-modal-hide ${containerHideClassName}` : ''
				}`}
				onClick={e => {
					e.preventDefault();
					if (onRequestClose) {
						onRequestClose(MODAL_CLOSE_REASON.CLICK_OUTSIDE, e);
					}
				}}
			>
				<div
					className={`core-modal-content ${contentClassName}`}
					onClick={e => {
						// need both as one was not enough
						e.stopPropagation();
						e.preventDefault();
					}}
					style={dimensionsStyle}
				>
					<Conditional if={showCloseButton}>
						<CloseButton
							className='modal-close-button'
							onClick={() =>
								onRequestClose?.(
									MODAL_CLOSE_REASON.CLOSE_BUTTON,
								)
							}
						>
							<CloseIcon />
						</CloseButton>
					</Conditional>
					{children}
				</div>
			</CoreModalContainer>
		) : null;
	}
}

export default Modal;
