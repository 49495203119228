import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { decrementAPICount } from 'Actions/apiCount';
import {
	changeLocationCurrency,
	receiveCurrencies,
	requestCurrencies,
} from 'Actions/currency';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

export const fetchCurrencies =
	({
		cookieCurrency,
		countryCode,
		currencyFromUrl,
		skipLocationBased = false,
	}: {
		cookieCurrency?: string;
		countryCode?: string;
		currencyFromUrl?: string;
		skipLocationBased?: boolean;
	} = {}) =>
	(dispatch: any, getState: any) => {
		dispatch(requestCurrencies());
		const fetchCurrencyMap = () => {
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v1/currency/list`;

			return fetch(url)
				.then(response => response.json())
				.then(json => {
					dispatch(receiveCurrencies({ currenciesList: json, url }));
				})
				.catch(err => {
					dispatch(setAPIServerAPIStatus(url, err.status));
					error(err);
					dispatch(decrementAPICount());
					notify.showNetworkError(err);
				});
		};

		const fetchUserLocationCurrency = () => {
			// do we want to support this for non Headout.com white labeled sites?
			const url = `${getApiCDNBaseUrlV2({
				state: getState(),
			})}/api/v1/currency?country=${countryCode}`;

			return fetch(url)
				.then(response => response.json())
				.then(json => {
					const { hasSupportedCurrency, currency } = json;
					if (!hasSupportedCurrency) return;

					dispatch(
						changeLocationCurrency({ currencyCode: currency.code }),
					);
				})
				.catch(err => {
					error(err);
					dispatch(decrementAPICount());
					notify.showNetworkError(err);
				});
		};

		const fetchPromisesArr = [fetchCurrencyMap];

		if (
			!skipLocationBased &&
			!currencyFromUrl &&
			!cookieCurrency &&
			countryCode
		) {
			fetchPromisesArr.push(fetchUserLocationCurrency);
		}
		return Promise.all(fetchPromisesArr.map((fn: any) => fn()));
	};
