import { captureException } from '@sentry/nextjs';

import { sendServerLog } from 'Server/logger';

import { trackEvent } from 'Utils/analytics';
import PlatformUtils from 'Utils/platformUtils';

import { store } from 'Store/configureStore';

import { CoralogixLogSeverity, LOG_LEVELS } from 'Constants/constants';

let CoralogixRum: any;

interface UserContext {
	hsid: string;
}

interface LogOptions {
	title: string;
	severity: 'INFO' | 'VERBOSE' | 'WARNING' | 'ERROR' | 'CRITICAL';
	id?: string;
	methodName?: string;
	metaData?: any;
	category?: string;
	traceToken?: string;
}

// dynamically loading coralogix-rum
const loadCoralogixRum = async (): Promise<any> => {
	if (!CoralogixRum) {
		//@ts-ignore
		// eslint-disable-next-line import/no-unresolved
		({ CoralogixRum } = await import('@coralogix/browser'));
	}
	return CoralogixRum;
};

// RUM configuration
const initConfig = {
	application: 'deimos',
	environment: process.env.NEXT_PUBLIC_NODE_ENV,
	public_key: process.env.NEXT_PUBLIC_CORALOGIX_RUM_INTEGRATION_TOKEN,
	coralogixDomain: 'EU1',
	version: process.env.NEXT_PUBLIC_APP_VERSION ?? 'v-default-1.0',
	sessionConfig: {
		sessionSampleRate: 100,
	},
	instrumentations: {
		xhr: false,
		fetch: false,
		web_vitals: false,
		interactions: false,
		custom: true,
		errors: true,
		long_tasks: false,
		resources: false,
	},
};

export const initializeCoralogixRum = async (): Promise<void> => {
	if (
		['production', 'test'].indexOf(
			process.env.NEXT_PUBLIC_NODE_ENV as string,
		) === -1
	) {
		return;
	}
	const coralogixRum = await loadCoralogixRum();
	coralogixRum?.init(initConfig);
};

export const sendCoralogixLog = async ({
	title,
	severity,
	id,
	methodName,
	metaData,
	category,
	traceToken,
}: LogOptions): Promise<void> => {
	if (
		['production', 'test'].indexOf(
			process.env.NEXT_PUBLIC_NODE_ENV as string,
		) === -1
	) {
		return;
	}

	try {
		const isClient = typeof window !== 'undefined';
		const state: any = store?.getState?.() ?? {};

		const errorDetails = {
			tgid: id,
			methodName,
			flowType: state?.product?.flowType ?? null,
			user: state?.user ?? null,
			device:
				state?.deviceStore ?? isClient
					? PlatformUtils.isDesktop()
					: null,
			category: category ?? null,
			traceToken: traceToken ?? null,
			agentType: isClient ? 'CLIENT' : 'SERVER',
		};

		const logData = {
			metaData: JSON.stringify({
				...errorDetails,
				metaData: (() => {
					if (metaData instanceof ErrorEvent) {
						return {
							message: metaData?.message,
							name: metaData.error?.name,
							stack: metaData.error?.stack,
						};
					} else {
						return metaData;
					}
				})(),
			}),
		};

		if (isClient) {
			const coralogixRum = await loadCoralogixRum();
			if (!coralogixRum) return;
			coralogixRum.log(CoralogixLogSeverity[severity], title, logData);
		} else {
			sendServerLog({
				level: severity,
				message: JSON.stringify(errorDetails),
				err: metaData,
				category,
			});
		}
	} catch (e) {
		// error sending log
	}
};

export const setCoralogixClientContext = async ({
	hsid,
}: UserContext): Promise<void> => {
	if (
		['production', 'test'].indexOf(
			process.env.NEXT_PUBLIC_NODE_ENV as string,
		) === -1
	) {
		return;
	}
	const coralogixRum = await loadCoralogixRum();
	coralogixRum?.setUserContext({
		user_id: hsid,
		user_name: hsid,
	});
};

export const sendException = (uncaughtException: any) => {
	if (
		['production', 'test'].indexOf(
			process.env.NEXT_PUBLIC_NODE_ENV as string,
		) === -1
	) {
		return;
	}

	const { hint, exception, title, category, methodName, metaData } =
		uncaughtException ?? {};

	const getErrorMessage = () => {
		if (typeof uncaughtException === 'string') return uncaughtException;
		if (title) return title;
		if (hint?.tags?.exceptionLabel) return hint.tags.exceptionLabel;
		return uncaughtException?.message || JSON.stringify(uncaughtException);
	};

	// send exception to sentry
	hint ? captureException(exception, hint) : captureException(exception);

	// send exception to mixpanel
	trackEvent({
		event: 'Javascript Error',
		Domain: '',
		Path: '',
		'Error Message': getErrorMessage(),
		'File Name': metaData?.filename ?? null,
		'Error Stack': metaData?.error?.stack ?? null,
	});

	// send exception log to coralogix
	sendCoralogixLog({
		title: getErrorMessage(),
		severity: LOG_LEVELS.ERROR as any,
		metaData: metaData ?? hint ?? uncaughtException,
		category: category ?? null,
		methodName: methodName ?? null,
	});
};
