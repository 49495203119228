import { captureException as sendLogToSentry } from '@sentry/nextjs';
import lscache from 'lscache';
import { parseCookies, setCookie } from 'nookies';
import * as store from 'store';

import { trackEvent } from 'Utils/analytics';
import { checkLastVisitedInDays } from 'Utils/dateUtils';
import { isServer } from 'Utils/envUtils';

import {
	BOOKING_SELECTION_LSCACHE_EXPIRY,
	COOKIE,
	LSCACHE_PARAM,
	STORE_ENTITY_TYPE,
	TIME,
} from 'Constants/constants';

import { getCookie } from './cookieUtils';
import { getNakedDomain } from './urlUtils';

export const isEnabled = () => {
	return store.enabled;
};

export const clear = () => {
	if (isEnabled()) {
		store.clearAll();
	}
};

// @ts-expect-error TS(7006): Parameter 'key' implicitly has an 'any' type.
export const read = (key, defaultVal: any = null) => {
	if (isEnabled()) {
		return store.get(key, defaultVal);
	}
	return null;
};

export const write = (key: any, value: any) => {
	if (isEnabled()) {
		try {
			store.set(key, value);
		} catch (e) {
			clear();
			sendLogToSentry(new Error('Local Store overflowed and cleared'));
		}
	}
};

export const remove = (key: any) => {
	if (read(key) !== null) {
		store.remove(key);
	}
};

export const getAllBookingsFromStore = () => {
	const bookings = read(STORE_ENTITY_TYPE.BOOKINGS);
	if (bookings) {
		const bookingsMap = {};
		for (const bookingId of bookings) {
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			bookingsMap[bookingId] = bookings[bookingId];
		}
		return bookingsMap;
	}
	return {};
};

export const storeDateSelection = (id: any, date: any) => {
	if (typeof window === 'undefined') return;
	const { hostname } = location;
	const nakedDomain = getNakedDomain(hostname);
	setCookie(
		null,
		COOKIE.USER_PREFERENCES,
		JSON.stringify({
			selectedDate: date,
		}),
		{
			domain: nakedDomain,
			httpOnly: false,
			maxAge: TIME.SECONDS_IN_DAY * 2,
			path: '/book',
		},
	);
	trackEvent({
		eventName: 'LocalStorage Date Added',
		'Tour Group ID': id,
		Date: date,
	});
};

export const readDateSelection = (id: any) => {
	if (!lscache) return null;

	lscache.setBucket(String(id));
	const date = lscache.get(LSCACHE_PARAM.DATE);

	const hasExpired = checkIfExpired(id, LSCACHE_PARAM.DATE);
	if (hasExpired) {
		trackEvent({
			eventName: 'Date Selection Expired',
			Date: date,
		});
	}

	if (date) {
		trackEvent({
			eventName: 'LocalStorage Date Loaded',
			'Tour Group ID': id,
			Date: date,
		});
	}
	return date;
};

export const storeTimeSelection = (id: any, time: any) => {
	if (!lscache) return;

	trackEvent({
		eventName: 'LocalStorage Time Added',
		'Tour Group ID': id,
		Time: time,
	});
	lscache.setBucket(String(id));
	lscache.flushExpired();
	lscache.set(LSCACHE_PARAM.TIME, time, BOOKING_SELECTION_LSCACHE_EXPIRY);
};

export const readTimeSelection = (id: any) => {
	if (!lscache) return null;

	lscache.setBucket(String(id));
	lscache.flushExpired();
	const time = lscache.get(LSCACHE_PARAM.TIME);
	if (time) {
		trackEvent({
			eventName: 'LocalStorage Time Loaded',
			'Tour Group ID': id,
			Time: time,
		});
	}
	return time;
};

export const readTourDateSelection = (id: any, tourId: any) => {
	if (typeof window === 'undefined') return null;

	const cookies = parseCookies();
	const userPreferences = cookies[COOKIE.USER_PREFERENCES];

	let selectedDate;

	if (userPreferences) {
		selectedDate = JSON.parse(userPreferences)?.selectedDate;
	}

	if (selectedDate)
		trackEvent({
			eventName: 'LocalStorage Date Loaded',
			'Tour Group ID': id,
			'Tour ID': tourId,
			Date: selectedDate,
		});

	return selectedDate;
};

export const storeTourTimeSelection = (id: any, tourId: any, time: any) => {
	if (!lscache) return;

	trackEvent({
		eventName: 'LocalStorage Time Added',
		'Tour Group ID': id,
		'Tour ID': tourId,
		Time: time,
	});
	lscache.setBucket(`${id}-${tourId}`);
	lscache.flushExpired();
	lscache.set(LSCACHE_PARAM.TIME, time, BOOKING_SELECTION_LSCACHE_EXPIRY);
};

export const readTourTimeSelection = (id: any, tourId: any) => {
	if (!lscache) return null;

	lscache.setBucket(`${id}-${tourId}`);
	lscache.flushExpired();
	const time = lscache.get(LSCACHE_PARAM.TIME);
	if (time) {
		trackEvent({
			eventName: 'LocalStorage Time Loaded',
			'Tour Group ID': id,
			'Tour ID': tourId,
			Time: time,
		});
	}
	return time;
};

const checkIfExpired = (bucket: any, key: any) => {
	// Prefix for all lscache keys
	const CACHE_PREFIX = 'lscache-';
	// Suffix for the key name on the expiration items in localStorage
	const CACHE_SUFFIX = '-cacheexpiration';
	const expiryKey = `${CACHE_PREFIX}${bucket}${key}${CACHE_SUFFIX}`;
	const expiry = store.get(expiryKey);
	const currentTime = Math.floor(new Date().getTime() / (60 * 1000));

	return !!expiry && currentTime >= expiry;
};

export const removeFromCache = (bucket: any, key: any) => {
	lscache.setBucket(String(bucket));
	lscache.remove(key);
};

export const saveEntitiesToLocalStorage = ({ key, value }: any) => {
	if (isServer()) return;
	value.lastVisited = Date.now();
	let recentlyViewedEntities = read(key) ? read(key) : [];
	if (recentlyViewedEntities) {
		const { searchType, cityCode, id } = value;
		recentlyViewedEntities = recentlyViewedEntities.filter(
			(entity: any) => {
				return entity?.lastVisited
					? checkLastVisitedInDays(entity.lastVisited, 30)
					: false;
			},
		);

		const ifEntityAlreadyExists = recentlyViewedEntities.findIndex(
			(entity: any) => {
				const {
					searchType: entityType,
					cityCode: entityCityCode,
					id: entityId,
				} = entity;

				if (searchType === 'city' && searchType === entityType) {
					return cityCode === entityCityCode;
				} else if (
					searchType === 'product' &&
					searchType === entityType
				) {
					return cityCode === entityCityCode
						? id === entityId
						: false;
				} else {
					return id === entityId;
				}
			},
		);

		if (ifEntityAlreadyExists !== -1)
			recentlyViewedEntities.splice(ifEntityAlreadyExists, 1);
		const newRecentlyViewedEntities = [value, ...recentlyViewedEntities];
		setTimeout(() => {
			write(key, newRecentlyViewedEntities);
		}, 0);
	} else {
		setTimeout(() => {
			write(key, [...recentlyViewedEntities, value]);
		}, 0);
	}
};

export const getRecentEntitiesFromLocalStore = (key: string) => {
	if (isServer()) return;
	let recentlyViewedEntities = read(key);
	return recentlyViewedEntities;
};

export const deDupeRecentProductIdList = (products: any[]) => {
	return products?.reduce((filteredProducts, current) => {
		// Check if there's already an object with the same id in the filteredProducts
		const existingObject = filteredProducts.find(
			(p: any) => p.id === current.id,
		);
		if (!existingObject) {
			filteredProducts.push(current);
		}
		return filteredProducts;
	}, []);
};
