import { combineReducers } from 'redux';

import { airportTransfersBookingDataStore } from 'Reducers/airportTransfersBookingDataStore';
import { app as appStore } from 'Reducers/app';
import { banners } from 'Reducers/banner';
import { biLinkStore } from 'Reducers/biLink';
import { blogPostsStore } from 'Reducers/blogs';
import { bookings, checkoutExtraInformation, seatSVGs } from 'Reducers/booking';
import { breakup as breakupStore } from 'Reducers/breakup';
import { calendarInvsStore } from 'Reducers/calendar';
import { cartReducer } from 'Reducers/cart';
import { categoriesStore } from 'Reducers/categories';
import { checkoutMetadataStore } from 'Reducers/checkoutMetadata';
import { city } from 'Reducers/city';
import { citiesList } from 'Reducers/cityList';
import { collectionsList } from 'Reducers/collectionList';
import { collectionsStore } from 'Reducers/collections';
import { currencies } from 'Reducers/currency';
import { deviceStore } from 'Reducers/device';
import { domainConfig } from 'Reducers/domainConfig';
import { faqStore } from 'Reducers/faq';
import { fetchingStatus } from 'Reducers/fetch';
import { gtm } from 'Reducers/gtm';
import { itineraryStore } from 'Reducers/itinerary';
import { languageReducer } from 'Reducers/language';
import { orderList } from 'Reducers/order';
import { page } from 'Reducers/page';
import { paymentCard } from 'Reducers/paymentCard';
import { paymentGatewayStore } from 'Reducers/paymentGateway';
import { personaStore } from 'Reducers/persona';
import { poiStore } from 'Reducers/poi';
import { pricingStore } from 'Reducers/pricing';
import { productStore, similarProductStore } from 'Reducers/product';
import { productList } from 'Reducers/productList';
import { promoStore } from 'Reducers/promo';
import { reviewStore } from 'Reducers/review';
import { searchResults } from 'Reducers/search';
import { selectedSeatsValidationDetails } from 'Reducers/selectedSeatsValidation';
import { serverStatus } from 'Reducers/serverStatus';
import { slotsStore } from 'Reducers/slots';
import { thunkErrorStore } from 'Reducers/thunkErrors';
import { travelerMediaStore } from 'Reducers/travelerMedia';
import { ugcUploads } from 'Reducers/ugcUploads';
import { userStore } from 'Reducers/user';
import { userGeoLocation } from 'Reducers/userGeoLocation';
import { voucherReducer } from 'Reducers/voucher';

export type TReducers = { [key: string]: any };

export const reducers: TReducers = {
	city,
	page,
	currencies,
	domainConfig,
	user: userStore,
	languageStore: languageReducer,
	voucherData: voucherReducer,
	deviceStore,
	serverStatus,
	productStore,
	productList,
	banners,
	bookings,
	paymentCard,
	seatSVGs,
	fetchingStatus,
	searchResults,
	similarProductStore,
	orderList,
	paymentGatewayStore,
	pricingStore,
	personaStore,
	faqStore,
	reviewStore,
	travelerMediaStore,
	slotsStore,
	checkoutExtraInformation,
	thunkErrorStore,
	ugcUploads,
	userGeoLocation,
	gtm,
	selectedSeatsValidationDetails,
	collectionsStore,
	categoriesStore,
	calendarInvsStore,
	blogPostsStore,
	checkoutMetadataStore,
	biLinkStore,
	appStore,
	citiesList,
	collectionsList,
	airportTransfersBookingDataStore,
	poiStore,
	itineraryStore,
	promoStore,
	cartStore: cartReducer,
	breakupStore,
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
