import React from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import { CloseIcon } from 'Assets/svg/checkout/CloseIcon';
import { WarningSvg } from 'Assets/svg/checkout/WarningSvg';
import { InfoIcon } from 'Assets/svg/infoIcon';

import { trackEvent } from 'Utils/analytics';
import { isServer } from 'Utils/envUtils';
import PlatformUtils from 'Utils/platformUtils';
import { UNKNOWN } from 'Utils/resolveResponse';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import LSpan from './localizedTags/localizedSpan';

const defaultTimeout = 8000; // ms

let toast: any = null;

const ToastNotification = styled.div<{
	topPosition: string | null;
}>`
	display: flex;
	align-items: center;
	position: fixed;
	top: -50px;
	left: 50%;
	z-index: 99999;
	pointer-events: none;
	transition: all 300ms ease;
	transform: translate(-50%, 0);
	border-radius: 0.75rem;
	background: ${colors.BRAND_COLORS.WHITE};
	padding: 1rem !important;
	text-align: center;

	&.confirmation {
		width: 100%;
		opacity: 0;
		background: ${colors.CONFIRMATION_GREEN};
		border-radius: 0;
		padding: 15px 0;
		top: 54px;
		transform: translate(-50%, 0);
		transition: opacity 350ms linear;

		&.show {
			opacity: 0.9;
			transform: translate(-50%, 0);
		}

		&.hide {
			opacity: 0;
			transform: translate(-50%, 0);
		}
	}

	&.show {
		transform: translate(
			-50%,
			${({ topPosition }) => topPosition ?? '110px'}
		);
	}

	&.hide {
		transform: translate(-50%, -125px);
	}

	&.success {
		background: ${colors.SUCCESS_GREEN};
	}

	&.error {
		background: #ffe6e6;
		span {
			color: #444444;
		}
	}

	&.warning {
		background: ${colors.FADED_PALE};

		span {
			color: ${colors.GREY_DS.G2};
		}
	}

	.warning-icon {
		margin-right: 0.5rem;
	}

	.close-icon {
		margin-left: 1.25rem;
		cursor: pointer;
		pointer-events: all;
	}

	span {
		white-space: pre-wrap;
		color: #fff;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.msg-container {
		display: flex;
	}

	.divider {
		color: rgba(214, 4, 4, 0.08);
		margin: 0.75rem 0;
	}

	.info-text {
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_XS)};
		color: ${colors.GREY_DS.G2};
	}

	&.info {
		${getFontDetailsByLabel(TYPE_LABELS.SUBHEADING_XS)};
		background-color: ${colors.BRAND_COLORS.WHITE};
		box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12),
			0px -1px 2px 0px rgba(0, 0, 0, 0.08);
		span {
			color: ${colors.GREY_DS.G2};
		}
	}

	.description {
		${getFontDetailsByLabel(TYPE_LABELS.PARAGRAPH_REGULAR)};
		margin-top: 0.5rem;
		margin-bottom: 0.75rem;
		text-align: left;
	}

	@media (max-width: 768px) {
		width: calc(100% - 3rem);
	}
`;

type ToastProps = {
	style?: any | boolean;
};

type ToastState = any;

/* React Notification Component */
/* eslint-disable react/no-multi-comp */
class Toast extends React.Component<ToastProps, ToastState> {
	state = {
		className: '',
		text: '',
		type: '',
		showErrorIcon: false,
		topPosition: null,
	};

	displayToast = (
		text: string,
		type: 'error' | 'warning' | 'info',
		timeout = defaultTimeout,
		showErrorIcon = false,
		topPosition: string | null,
	) => {
		this.setState({ text, type, showErrorIcon, topPosition });
		setTimeout(this.show, 100); // wait 100ms after the component is called to animate toast.
		setTimeout(this.hide, timeout);
	};

	hide = () => {
		this.setState({ className: 'hide' });
	};

	show = () => {
		this.setState({ className: 'show' });
	};

	render() {
		const { text, type, className, showErrorIcon, topPosition } =
			this.state;
		return (
			<ToastNotification
				className={`toast-notification ${type} ${className}`}
				id='toast'
				topPosition={topPosition}
			>
				{type === 'error' &&
					(PlatformUtils.isDesktop() || showErrorIcon) && (
						<WarningSvg className='warning-icon' />
					)}
				{type === 'warning' && <InfoIcon className='warning-icon' />}
				<LSpan>{text}</LSpan>
				{(type === 'error' || type === 'warning') &&
					PlatformUtils.isDesktop() && (
						<CloseIcon className='close-icon' onClick={this.hide} />
					)}
			</ToastNotification>
		);
	}
}

/* Public functions */

const sendAnalyticsEvent = (err: any) => {
	const { message, code } = err;
	trackEvent({
		eventName: 'Error Viewed',
		'Error Code': code,
		'Error Message': message,
	});
};

/* Show Animated Toast Message */
const show = (
	text: string,
	type: string,
	timeout = defaultTimeout,
	trackProps = {},
	showErrorIcon = false,
	topPosition: string | null = null,
) => {
	if (isServer()) return;
	// Render Component with Props.
	if (toast) {
		if (type === 'error') {
			trackEvent({
				eventName: 'Error Viewed',
				'Error Message': text,
				...trackProps,
			});
		} else if (type === 'warning') {
			trackEvent({
				eventName: 'Warning Viewed',
				'Warn Message': text,
				...trackProps,
			});
		}
		toast.displayToast(text, type, timeout, showErrorIcon, topPosition);
	}
};

const showNetworkError = (err: any) => {
	if (isServer() || !err) return;
	// Send Analytics Events in case of API Failure
	sendAnalyticsEvent(err);
	if (err?.status && (err.localisedMessage || err.message))
		show(err.localisedMessage || err.message, 'error');
	if (!(err.message || err.localisedMessage)) show(UNKNOWN, 'error');
};

const showConfirmation = (msg: any, timeout: any) => {
	if (isServer() || !msg) return;
	show(msg, 'confirmation', timeout);
};

/* Export notification container */
// eslint-disable-next-line react/display-name
export default function ToastFunc() {
	return <Toast ref={node => (toast = node) as any} />;
}

/* Export notification functions */
export const notify = {
	show,
	showNetworkError,
	showConfirmation,
};
/* eslint-enable react/no-multi-comp */
