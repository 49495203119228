import { notify } from 'Components/common/notify';

import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import { receiveLanguages, requestLanguages } from 'Actions/language';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

export const fetchLanguages = () => (dispatch: any, getState: any) => {
	const url = `${getApiCDNBaseUrlV2({
		state: getState(),
	})}/api/v1/language/list`;

	dispatch(requestLanguages());

	return fetch(url)
		.then(response => response.json())
		.then(json => {
			dispatch(receiveLanguages({ list: json, url }));
		})
		.catch(err => {
			dispatch(setAPIServerAPIStatus(url, err.status));
			error(err);
			notify.showNetworkError(err);
		});
};
