import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrencyCodesList, getCurrentCountryCode } from 'Utils/stateUtils';
import { getApiBaseUrl } from 'Utils/urlUtils';

import { setGeoLocationCurrency } from 'Actions/currency';
import {
	receivedUserCountry,
	requestUserCountry,
} from 'Actions/userGeoLocation';

export const fetchUserGeoLocation = () => (dispatch: any, getState: any) => {
	const url = `${getApiBaseUrl()}/api/v2/geolocate/city`;
	dispatch(requestUserCountry());
	const currentCountryCode = getCurrentCountryCode(getState());
	return fetch(url)
		.then(response => response?.json())
		.then(json => {
			const country = json.currentCountry.code || currentCountryCode;
			const currencyCode = json.currentCountry.currency.code;
			dispatch(receivedUserCountry(country, currentCountryCode));
			if (getCurrencyCodesList(getState()).includes(currencyCode)) {
				dispatch(
					setGeoLocationCurrency({
						currencyCode,
					}),
				);
			}
		})
		.catch(err => {
			error(err);
			dispatch(
				receivedUserCountry(currentCountryCode, currentCountryCode),
			);
		});
};
