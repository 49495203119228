/* eslint-disable-next-line no-restricted-imports */
import styled, { css, keyframes } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

export const PageLoaderContainer = styled.div`
	width: 100%;
	height: 100vh;
	text-align: center;
	position: fixed;
	z-index: 101; // Header is 100
	top: 0;
	left: 0;
	background-color: #fff;

	@media (max-width: 768px) {
		height: 100%;
		&.more-date-loader {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
	}
`;

export const PageLoaderWrapper = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const InlineLoader = styled.div<{ $isNotVisible?: boolean }>`
	position: relative;
	width: 2.25rem;
	height: 2.25rem;
	display: flex;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.15s;

	&:after,
	&:before {
		content: '';
		position: absolute;
		border-radius: 100%;
		border: 0.25rem solid ${colors.GREY_DS.G8};
		width: 100%;
		height: 100%;
	}

	&:before {
		border-top-color: ${colors.PURPS};
		z-index: 1;
		animation: ${SpinAnimation} 1s infinite;
	}

	${({ $isNotVisible }) =>
		$isNotVisible &&
		css`
			visibility: hidden;
			height: 0;
			opacity: 0;
		`}
`;

export const LoadingText = styled.div`
	margin-top: 1rem;
	color: ${colors.GREY['44']};
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};

	@media (max-width: 768px) {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
	}
`;

export const GIFContainer = styled.div<{
	$isLoaded?: boolean;
}>`
	position: relative;
	height: 5.5rem;
	width: 5.5rem;
	opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0)};
	transition: opacity 0.3s linear;

	@media only screen and (min-width: 768px) {
		height: 8rem;
		width: 8rem;
	}
`;
