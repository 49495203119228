import { CheckoutFieldType } from 'Components/common/paymentFields/interface';

import dayjs from 'Utils/dayjsUtil';
import { isNonEmptyObject } from 'Utils/gen';

import {
	ADYEN,
	FIELD_NAME,
	FLEXIBLE_START_TIME,
	LOG_CATEGORIES,
	LOG_LEVELS,
	PAYMENT_FIELD_NAME,
	ZENDESK_VALIDITY_REGEX,
} from 'Constants/constants';
import { INPUT_FIELD_IDS } from 'Constants/inputFields';
import { strings } from 'Constants/strings';

import { sendCoralogixLog } from './coralogix/log';

export const UNKNOWN_ERROR =
	'Oops. Some error occurred. Try reloading the page';

export const checkCardDetail = (
	number: any,
	minLength: any,
	maxLength: any,
	minValue: any,
	maxValue: any,
) => {
	let isValid = true;
	const regex = /^\d+$/;
	isValid = isValid && regex.test(number);
	isValid = isValid && number.length >= minLength;
	isValid = isValid && number.length <= maxLength;
	if (minValue !== null) {
		isValid = isValid && Number(number) >= minValue;
	}
	if (maxValue !== null) {
		isValid = isValid && Number(number) <= maxValue;
	}
	return isValid;
};

export const getCustomerCount = ({ selectionMap, groupSize }: any) =>
	selectionMap && Object.keys(selectionMap).length !== 0
		? Object.values(selectionMap).reduce(
				(people, total) => (total as any) + (people as any),
		  )
		: groupSize;

export const getTotalPassengersFromBooking = (booking: any) => {
	const { selectionMap, groupSize } = booking;
	return getCustomerCount({ selectionMap, groupSize });
};

export const checkFullName = (fullName: any) => {
	const parts = fullName && fullName.trim().split(' ');
	const hasAtLeastTwoParts = parts && parts.length >= 2;
	let hasAtLeastOneNonInitial = false;
	for (const part of parts) {
		if (part.length > 1) {
			hasAtLeastOneNonInitial = true;
			break;
		}
	}

	return hasAtLeastTwoParts && hasAtLeastOneNonInitial;
};

export const validateUniqueFullName = ({
	userFields,
	currentSelectionIndex,
}: {
	userFields: any;
	currentSelectionIndex: number;
}): string | null => {
	if (!userFields) {
		return null;
	}

	const currentSelectedUserFieldNameValue = userFields[currentSelectionIndex]
		?.find((field: any) => field.name === FIELD_NAME.NAME)
		?.value?.trim()
		?.toLocaleLowerCase();

	if (!currentSelectedUserFieldNameValue) {
		return null;
	}

	const hasSameFullNameValue = userFields.some((user: any, idx: number) => {
		if (idx === currentSelectionIndex) {
			return false;
		}

		const nameUserField = user.find(
			(field: any) => field.name === FIELD_NAME.NAME,
		);

		return (
			nameUserField?.value?.trim()?.toLocaleLowerCase() ===
			currentSelectedUserFieldNameValue
		);
	});

	return hasSameFullNameValue ? strings.USER_FIELDS_UNQIUE_NAME : null;
};

export const checkEmail = (
	email: string,
	checkZendeskValidity: boolean = false,
) => {
	const structureRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const isLengthValid =
		email?.split?.('@')?.[0]?.length <= 64 &&
		email?.split?.('@')?.[1]?.length <= 255;
	const isEmailStructureValid =
		email &&
		structureRegex.test(email) &&
		!email.endsWith('.con') &&
		email.split('@')[0] &&
		isLengthValid;
	const isEmailZendeskValid =
		!checkZendeskValidity || ZENDESK_VALIDITY_REGEX.test(email);
	if (!isEmailZendeskValid)
		sendCoralogixLog({
			title: 'Email zendesk validity check failed',
			severity: LOG_LEVELS.WARNING as any,
			methodName: 'checkEmail',
			category: LOG_CATEGORIES.EMAIL_CHECK_FAILED,
			metaData: {
				email,
				invalidCharacters: email
					.split('')
					.filter(char => !ZENDESK_VALIDITY_REGEX.test(char)),
			},
		});
	return isEmailStructureValid && isEmailZendeskValid;
};

export const getZendeskInvalidCharacters = (email: string) => {
	if (!email) return '';
	const invalidCharacters = email
		.split('')
		.filter(char => !ZENDESK_VALIDITY_REGEX.test(char));
	// Removing duplicates and converting them to a string by separating them by `, `
	return `${invalidCharacters
		.filter((item, index) => invalidCharacters.indexOf(item) === index)
		.slice(0, 4)
		.join(', ')}${invalidCharacters.length <= 4 ? '' : '....'}`;
};

export const checkPhone = (phone: any) => {
	const regex = /^[-+ \d]+$/;
	return phone && regex.test(phone);
};

export const checkAmount = (amount: any) => {
	const regex = /^[\d]+$/;
	return regex.test(amount) && Number(amount) > 0;
};

export const formatErrorMessage = (errorMessage: any) => {
	return (
		strings.CPUD_ERROR +
		errorMessage?.[0].toLowerCase() +
		errorMessage?.slice(1)
	);
};
export const getUserFieldErrorMessage = (field: any) => {
	const { name } = field;
	switch (name) {
		case FIELD_NAME.NAME:
			return formatErrorMessage(strings.CPUD_ERROR_NAME);
		case FIELD_NAME.EMAIL:
			return formatErrorMessage(strings.CPUD_ERROR_EMAIL);

		case FIELD_NAME.PHONE:
			return formatErrorMessage(strings.CPUD_ERROR_PHONE);

		case FIELD_NAME.WEIGHT:
			return formatErrorMessage(strings.CPUD_ERROR_WEIGHT);

		default:
			return formatErrorMessage(strings.CPUD_ERROR_VALUE);
	}
};

export const getPaymentFieldErrorMessage = (field: string) => {
	switch (field) {
		case PAYMENT_FIELD_NAME.CARD_HOLDER_NAME:
			return strings.CPUD_CARD_ERROR_NAME;
		case PAYMENT_FIELD_NAME.CARD_NUMBER:
			return strings.CPUD_CARD_ERROR_NUMBER;
		case PAYMENT_FIELD_NAME.MONTH:
			return strings.CPUD_CARD_ERROR_MONTH;
		case PAYMENT_FIELD_NAME.YEAR:
			return strings.CPUD_CARD_ERROR_YEAR;
		case PAYMENT_FIELD_NAME.CVV:
			return strings.CPUD_CARD_ERROR_CVV;
		default:
			return strings.CPUD_ERROR_VALUE;
	}
};

export const getFieldId = ({ name }: any) => {
	switch (name) {
		case FIELD_NAME.EMAIL:
			return INPUT_FIELD_IDS.USER_FIELD_EMAIL;
		case FIELD_NAME.PHONE:
			return INPUT_FIELD_IDS.USER_FIELD_PHONE;
		case PAYMENT_FIELD_NAME.CARD_HOLDER_NAME:
			return INPUT_FIELD_IDS.PAYMENT_FIELD_CARD_NAME;
		case PAYMENT_FIELD_NAME.CARD_NUMBER:
			return INPUT_FIELD_IDS.PAYMENT_FIELD_CARD_NUMBER;
		case PAYMENT_FIELD_NAME.MONTH:
			return INPUT_FIELD_IDS.PAYMENT_FIELD_EXP_MONTH;
		case PAYMENT_FIELD_NAME.YEAR:
			return INPUT_FIELD_IDS.PAYMENT_FIELD_EXP_YEAR;
		case PAYMENT_FIELD_NAME.CVV:
			return INPUT_FIELD_IDS.PAYMENT_FIELD_CVV;
		default:
			return '';
	}
};

export const validatePromoCodeInBookingData = () => ({
	isValid: true,
	error: '',
});

export const validateCardDetailsInBookingData = (cardDetails: any) => {
	const {
		cardHolderName,
		cardNumber,
		month,
		year,
		cvv,
		encryptedCardDetails,
		isValid: isCardValid,
		paymentFieldValidities,
	} = cardDetails;

	const nameError =
		cardHolderName && cardHolderName.length > 0
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CARD_HOLDER_NAME);

	if (isCardValid) return { isValid: !nameError, error: nameError };

	let numberError, monthError, yearError, cvvError;

	if (isNonEmptyObject(encryptedCardDetails)) {
		numberError = encryptedCardDetails[ADYEN.FIELD_TYPE.CARD_NUMBER]
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CARD_NUMBER);
		monthError = encryptedCardDetails[ADYEN.FIELD_TYPE.EXPIRY_MONTH]
			? ''
			: `${getPaymentFieldErrorMessage(
					PAYMENT_FIELD_NAME.MONTH,
			  )}/${getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.YEAR)}`;
		yearError = encryptedCardDetails[ADYEN.FIELD_TYPE.EXPIRY_YEAR]
			? ''
			: `${getPaymentFieldErrorMessage(
					PAYMENT_FIELD_NAME.MONTH,
			  )}/${getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.YEAR)}`;
		cvvError = encryptedCardDetails[ADYEN.FIELD_TYPE.CVV]
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CVV);
	} else if (isNonEmptyObject(paymentFieldValidities)) {
		numberError = paymentFieldValidities[CheckoutFieldType.CARD_NUMBER]
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CARD_NUMBER);
		monthError = paymentFieldValidities[CheckoutFieldType.EXPIRY_DATE]
			? ''
			: `${getPaymentFieldErrorMessage(
					PAYMENT_FIELD_NAME.MONTH,
			  )}/${getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.YEAR)}`;
		yearError = paymentFieldValidities[CheckoutFieldType.EXPIRY_DATE]
			? ''
			: `${getPaymentFieldErrorMessage(
					PAYMENT_FIELD_NAME.MONTH,
			  )}/${getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.YEAR)}`;
		cvvError = paymentFieldValidities[CheckoutFieldType.CVV]
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CVV);
	} else {
		numberError = checkCardDetail(cardNumber, 1, 19, null, null)
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CARD_NUMBER);
		monthError = checkCardDetail(month, 1, 2, 1, 12)
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.MONTH);
		yearError = checkCardDetail(year, 2, 2, 17, 99)
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.YEAR);
		cvvError = checkCardDetail(cvv, 3, 4, null, null)
			? ''
			: getPaymentFieldErrorMessage(PAYMENT_FIELD_NAME.CVV);
	}

	const error =
		nameError || numberError || monthError || yearError || cvvError;

	const isValid = !error;
	return { isValid, error };
};

export const validateDate = (dateString: any) => {
	const dateDayJS = dayjs(dateString, 'YYYY-MM-DD');
	const yesterdayDayJS = dayjs().subtract(1, 'days').startOf('day');
	return dateDayJS.isValid() && dateDayJS.isSameOrAfter(yesterdayDayJS, 'd');
};

export const validateTime = (timeString: any) =>
	dayjs(timeString, 'HH:mm:ss').isValid() ||
	dayjs(timeString, 'HH:mm').isValid();

export const isValidAmount = (amount: any) => {
	const regex = /^\d*(\.\d{0,2})?$/;
	return regex.test(amount);
};

export const parseValidatedAmount = (validatedAmount: any, scale: any) => {
	if (validatedAmount) {
		return scale === 2
			? parseFloat(validatedAmount).toFixed(2)
			: Math.floor(validatedAmount).toString();
	}
	return '';
};

export const isParsedAmountValid = (parsedAmount: any, scale: any) => {
	const regex = scale === 2 ? /^[0-9]\d*((\.\d{0,2})?)$/ : /^[1-9][0-9]*$/;
	return parsedAmount && regex.test(parsedAmount);
};

export const validateTourPaxSelections = (
	tourSelectionMap: any,
	tourGroupSize: any,
) => {
	const initPaxCount = tourGroupSize ? tourGroupSize : 0;
	const totalPax = Object.values(tourSelectionMap || {}).reduce(
		(acc, val) => (acc as any) + (val as any),
		initPaxCount,
	);

	return (totalPax as any) > 0;
};

export const validateAllSelections = ({ booking, pricing }: any) => {
	const { comboSelections, selectedDate, selectedTime, selectedTourId } =
		booking;
	const { inventoryMapByTourDate } = pricing;
	const isCombo = Object.keys(comboSelections || {}).length;

	if (isCombo) {
		return Object.entries(comboSelections || {}).every(
			([tourId, selections]) => {
				// @ts-expect-error TS(2339): Property 'selectedTourDate' does not exist on type... Remove this comment to see the full error message
				const { selectedTourDate, selectedTourTime } = selections;
				const inventoryTimeSlots =
					inventoryMapByTourDate?.[tourId]?.[selectedTourDate] ?? [];
				const isDateValid = inventoryTimeSlots?.length > 0;
				const isTimeValid =
					selectedTourTime === FLEXIBLE_START_TIME ||
					inventoryTimeSlots.find(
						(slot: any) => slot.startTime === selectedTourTime,
					);
				return isDateValid && isTimeValid;
			},
		);
	} else {
		const inventoryTimeSlots =
			inventoryMapByTourDate?.[selectedTourId]?.[selectedDate];
		const isDateValid = inventoryTimeSlots?.length > 0;
		const isTimeValid =
			selectedTime === FLEXIBLE_START_TIME ||
			inventoryTimeSlots?.find(
				(slot: any) => slot.startTime === selectedTime,
			);

		return isDateValid && isTimeValid;
	}
};

export const isNumber = (value: string) => {
	return !isNaN(Number(value));
};
