export const getFirstName = (fullName: any) => {
	if (!fullName) return '';

	const name = fullName.trim();
	if (name && name.indexOf(' ') > -1) {
		return name.slice(0, name.indexOf(' '));
	}

	return name;
};

export const getFirstLastName = (fullName: any) => {
	if (fullName) {
		return {
			firstName: fullName.split(' ').slice(0, 1).join(' '),
			lastName: fullName.split(' ').slice(1).join(' '),
		};
	}
	return {
		firstName: '',
		lastName: '',
	};
};

export const capitalizeFirstLetter = (str: any): string => {
	if (str) {
		return str
			.split(' ')
			.map((word: any) => {
				const wordAsSmall = word.toLowerCase();
				return wordAsSmall
					? `${wordAsSmall[0].toUpperCase()}${wordAsSmall.slice(1)}`
					: '';
			})
			.join(' ');
	}
	return '';
};

export const capitalizeOnlyFirstLetter = (s: any) =>
	s?.[0]?.toUpperCase() + s?.slice(1) || '';

export const startsWith = (str: any, prefixes: any) => {
	if (str) {
		for (const prefix of prefixes) {
			if (str.startsWith(prefix)) return true;
		}
	}

	return false;
};

export const stripNonIntegers = (value: any) => {
	return value.replace(/[^0-9]/g, '');
};

export const replaceNotAlphaNumbericWithHyphen = (text: any) => {
	// replace non alpha numberic characters with space, then trim, then replace multiple spaces with one hyphen.
	return text
		.replace(/[^a-z0-9]/gi, ' ')
		.trim()
		.replace(/\s\s+/g, ' ')
		.replace(/\s/g, '-');
};

export const truncateToWord = (str: any, maxLength: any) => {
	// trim the string to the maximum length
	let trimmedString = str?.substr(0, maxLength);

	// re-trim if we are in the middle of a word
	if (trimmedString && trimmedString !== str) {
		trimmedString = trimmedString
			.substr(0, trimmedString.lastIndexOf(' '))
			.concat('...');
	}

	return trimmedString;
};

// @ts-expect-error TS(7019): Rest parameter 'params' implicitly has an 'any[]' ... Remove this comment to see the full error message
export const joinWithDot = (...params) => {
	return params.join('.');
};

export const getArrayFromString = (arrayAsString: any) => {
	if (!arrayAsString || !(typeof arrayAsString === 'string')) return [];
	return arrayAsString.split(',').filter(s => s);
};

export const stripUnnecessaryDecimals = (string: any) => {
	return string.replace(/\.0+$/, '');
};

export const replaceDotByComma = (string: any) => string.replace(/\./, ',');

export const joinScripts = (scripts: any) =>
	scripts.reduce(
		(script: any, allScripts: any) => `${allScripts} \n ${script}`,
		'',
	);

export const joinStyles = (styles: any) =>
	styles.reduce(
		(style: any, allStyles: any) => `${allStyles} \n ${style}`,
		'',
	);

export const isAlphabeticString = (str: any) => /^[a-z]*$/gi.test(str);

export const removeScripts = (html: string) => {
	const scriptTagPattern = /<script[\s\S]*?>[\s\S\n]*?<\/script>/gi;
	const modifiedHTML = html.replace(scriptTagPattern, '');

	return modifiedHTML;
};

/**
 * Safely converts HTML into simple string. removes scripts.
 * @param htmlString
 * @returns innerText like string (note: does not handle decoding HTML entites)
 */
export const extractInnerTextFromHTMLString = (htmlString: string) => {
	if (!htmlString) return '';

	let tempHtmlString = removeScripts(htmlString);
	const tagRegex = /<[^>]*>/g;
	const textWithoutTags = tempHtmlString.replace(tagRegex, '');

	const whitespaceRegex = /\s+/g;
	const textWithoutMultipleSpaces = textWithoutTags.replace(
		whitespaceRegex,
		' ',
	);

	const trimmedText = textWithoutMultipleSpaces.trim();

	return trimmedText;
};

export const truncateText = (
	text: string,
	maxLength: number,
	suffix = '...',
) => {
	if (text.length <= maxLength) {
		return text;
	}

	return text.substring(0, maxLength - suffix.length) + suffix;
};

export const extractFirstListItem = (markdown: any) => {
	// @ts-expect-error
	const listItemRegex = /^\s*-\s+(.*?)(?=\n\s*-|\n\n|$)/s;
	const match = listItemRegex.exec(markdown);

	if (match && match[1]) {
		return match[1].trim();
	}

	return null;
};

/**
 * Removes all characters after the specified character in a string. including the character itself.
 * If the specified character is not found, the original string is returned.
 */
export function removeAfterCharacter(str: string, char: string) {
	return str.slice(0, str.indexOf(char) || str.length);
}

export function getHumanReadableStringFromSanitizedIdentifier(str: string) {
	return capitalizeFirstLetter(str.replaceAll('_', ' '));
}
